import React from 'react';
import { ResponsiveContext, Box, Layer, Text, Select, Anchor, Button, RadioButtonGroup } from 'grommet';
import { FormClose } from 'grommet-icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { TitleSideBar, TitleField } from '../../../components';

import { closeLayer } from '../../../actions/layers';
import { searchCityOptions, searchDistrictOptions } from '../../../actions/select-options';
import { changeValues, cleanValues } from '../../../actions/form';
import { searchCitiesVoting } from '../../../actions/search-election';

import electionsOptions from '../../../constants/elections';

const FormSearch = (props) => {
  if(!props.toggleLayer || props.toggleLayer !== 'search') return null;

  const onSearch = (params) => {
    const { searchCitiesVoting, closeLayer } = props;

    searchCitiesVoting({
      values: params
    });

    closeLayer();
  }

  const {
    // data
    statesOptions,

    // functions
    closeLayer,
    changeValues,
    cleanValues,

    // fields
    electionId,
    stateId,
  } = props;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Layer
          responsive={false}
          full='vertical'
          position='left'
          onClickOutside={closeLayer}
          onEsc={closeLayer}
        >
          <Box
            fill='vertical'
            width={ size === 'xsmall' || size === 'small' ? '100vw' : '60vw'}
            pad={{ top: 'large', bottom: 'xsmall' }}
            overflow='auto'
            flex
          >
            <Box
              direction='row'
              justify='between'
              margin={{ bottom: 'medium', horizontal: 'large' }}
            >
              <TitleSideBar value='Filtrar' />

              <Button
                plain
                title='Fechar'
                icon={<FormClose />}
                onClick={closeLayer}
              />
            </Box>

            {/* Campos de busca */}
            <Box flex overflow='auto'>

              { /* City */ }
              {/*<Box
                margin={{
                  vertical: size === 'small' ? 'medium' : 'small',
                  horizontal: 'large'
                }}
                flex={{ shrink: 0 }}
              >
                <Box direction='row' align='center' gap='xsmall'>
                  <TitleField text='Cidade' />

                  {cityId && (
                    <Anchor
                      size='small'
                      label='limpar'
                      onClick={() => {
                        changeValues({ fieldName: 'cityId', value: null });
                      }}
                    />
                  )}
                </Box>

                <Select
                  options={citiesOptions}
                  labelKey={
                    ({ name, stateAbbrev }) => (
                      <Box
                        pad={{ horizontal: 'small', vertical: 'small' }}
                        gap='xsmall'
                        direction='row'
                        align='baseline'
                      >
                        <Text size='medium' color='dark-1'>{name}</Text>
                        <Text size='small' color='dark-6'>{stateAbbrev}</Text>
                      </Box>
                    )
                  }
                  valueKey={{ key: 'id', reduce: true }}
                  value={cityId ?? ''}
                  emptySearchMessage='Selecione uma opção'
                  onChange={({ value: nextValue }) => changeValues({ fieldName: 'cityId', value: nextValue }) }
                  onSearch={ text => onSearchCities({ text }) }
                >
                  {({ name, stateAbbrev }) => {
                    return (
                      <Box
                        pad={{ horizontal: 'small', vertical: 'small' }}
                        gap='small'
                        direction='row'
                        align='baseline'
                      >
                        <Text size='medium' color='dark-1'>{name}</Text>
                        <Text size='small' color='dark-6'>{stateAbbrev}</Text>
                      </Box>
                    )
                  }}
                </Select>
              </Box>*/}


              { /* Elections */ }
              <Box
                margin={{
                  vertical: size === 'small' ? 'medium' : 'small',
                  horizontal: 'large'
                }}
                flex={{ shrink: 0 }}
              >
                <Select
                  options={electionsOptions}
                  labelKey='name'
                  valueKey={{ key: 'id', reduce: true }}
                  value={electionId ?? ''}
                  emptySearchMessage='Selecione uma opção'
                  onChange={({ value: nextValue }) =>
                    changeValues({ fieldName: 'electionId', value: nextValue })
                  }
                />
              </Box>


              { /* Estados */ }
              <Box
                margin={{
                  vertical: size === 'small' ? 'medium' : 'small',
                  horizontal: 'large'
                }}
                flex={{ shrink: 0 }}
              >
                <Box direction='row' align='center' gap='xsmall'>
                  <TitleField text='Estado' />

                  {stateId && (
                    <Anchor
                      size='small'
                      label='limpar'
                      onClick={() => changeValues({ fieldName: 'stateId', value: null })}
                    />
                  )}
                </Box>

                <Box
                  pad={{ vertical: 'small' }}
                >
                  <RadioButtonGroup
                    name='stateId'
                    value={stateId ?? ''}
                    options={
                      statesOptions.map(({ id, name }) => ({
                        label: name,
                        id: `stateId_${id}`,
                        value: id.toString(),
                      }))
                    }
                    onChange={event => { changeValues({ fieldName: 'stateId', value: event.target.value }) }}
                    wrap={true}
                    direction='row'
                  >
                    {(option, { checked }) => {
                      let background = null;
                      let borderColor = 'dark-5';

                      if(checked) {
                        background = 'brand';
                        borderColor = 'brand';
                      }

                      return (
                        <Box
                          direction='row'
                          gap='small'
                          align='center'
                          width={{ min: 'medium' }}
                          margin={{ bottom: 'small' }}
                        >
                          <Box
                            width={'20px'}
                            height={'20px'}
                            background={background}
                            round='xxsmall'
                            border={{
                              color: borderColor
                            }}
                          />
                          <Text color='dark-2' size='small'>{option.label}</Text>
                        </Box>
                      )
                    }}
                  </RadioButtonGroup>
                </Box>
              </Box>
            </Box>

            {/* Botões Busca/limpa */}
            <Box
              direction='row'
              pad={{ vertical: 'medium' }}
              margin={{ horizontal: 'large' }}
              justify='center'
              align='center'
              gap='small'
            >
              <Box width='small'>
                <Button
                  color='dark-3'
                  label='Limpar'
                  onClick={() => cleanValues() }
                />
              </Box>

              <Box width='small'>
                <Button
                  color='brand'
                  primary
                  label='Buscar'
                  title={
                    !electionId || !stateId ? 'Selecione um estado.' : ''
                  }
                  disabled={!electionId || !stateId}
                  onClick={() =>
                    onSearch({
                      electionId,
                      id: stateId,
                    })
                  }
                />
              </Box>
            </Box>
          </Box>
        </Layer>
      )}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ layerReducer, queriesReducer, formReducer }) => ({
  toggleLayer: layerReducer.toggleLayer,
  statesOptions: queriesReducer.statesOptions,

  electionId: formReducer.electionId || 1,
  stateId: formReducer.stateId,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    closeLayer,
    searchCityOptions,
    searchDistrictOptions,
    changeValues,
    cleanValues,
    searchCitiesVoting,
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(FormSearch);
